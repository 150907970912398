@import '../../../styles/commonConfig';
#browser_version{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f3f7f9;
  z-index: $level_z_index_max;
  .browser_version_container{
    position: absolute;
    width: 800px;
    height:123px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    img{
      float:left;
      margin-top: 12px;
    }
    .browser_version_content{
      float: right;
      width: 630px;
      height: 100%;
      color: #333;
      h1{
        font-size: 36px;
        font-family: Microsoft YaHei;
      }
      p{
        margin-top: 28px;
        line-height: 14px;
        font-size: 14px;
      }
    }
  }
}
