/*$img_baseURL: '~@/assets/img/';*/
/*$img_baseURL: '~@/../static/img/';*/
$img_baseURL: '~@/../static/regular/img/';/* ~前缀才会解析为模块路径  @是配置里src */
$img_configURL: '~@/../static/configurable/img/';/* ~前缀才会解析为模块路径  @是配置里src */

$level_z_index_1  : 1;
$level_z_index_2  : 11;
$level_z_index_3  : 13;
$level_z_index_max: 9999;


