@charset "UTF-8";
/* 始终保持footer在底部 */
.ws_pageContainer {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  min-height: 100%;
}
.ws_pageContainer .ws_pageContent {
  width: 100%;
}
.ws_pageContainer #footer {
  width: 100%;
}

/*清除浮动*/
.clearFloat:after {
  display: block;
  content: '';
  clear: both;
}

.clearfix {
  clear: both;
}

/*水平居中*/
.text_center {
  text-align: center;
}

/*左浮动*/
.pull_left {
  float: left;
}

/*按钮*/
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  outline: none;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
}

.btn_default {
  background: #fff;
  border: 1px solid #ccc;
  color: #333;
}

.btn_primary {
  background: #1394FF;
  border: 1px solid #1394FF;
  color: #fff;
}

.btn_new {
  background-color: #1394ff;
  padding: 8px 30px;
  box-shadow: none;
  border: 1px solid #1394FF;
}

/* 箭头 */
.foldIcon {
  border: 5px solid transparent;
  border-top-color: #747474;
  position: absolute;
  transition: transform .5s ease-out;
  transform-origin: 50% 25%;
}
.foldIcon:before {
  content: '';
  position: absolute;
  border: 5px solid transparent;
  border-top-color: #343C48;
  margin-left: -5px;
  margin-top: -6px;
}

/* 文本蓝色下划线 */
.under_line {
  padding-bottom: 5px;
  border-bottom: 1px solid #1394ff;
}

/* 首页各栏头部 */
.ws_serve_head h1 {
  font-size: 36px;
  color:#343c48;
}
.ws_product  .ws_serve_head h1{
  color:#fff;
}
.ws_serve_head p {
  max-width: 760px;
  margin: 20px auto 0 auto;
  font-size: 16px;
  line-height: 22px;
}

/* 首页各栏头部 */
.ws_serve_head_mini h1 {
  font-size: 18px;
  color: #343C48;
}
.ws_serve_head_mini p {
  max-width: 760px;
  margin: 20px auto 0 auto;
  padding: 0 70px;
  line-height: 22px;
  font-size: 12px;
  color: #343C48;
}

/* 滚动条样 */
.scrollBar::-webkit-scrollbar {
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  width: 5px;
  height: 5px;
  background-color: rgba(34, 34, 34, 0.94);
}

.scrollBar.scrollBarSmall::-webkit-scrollbar {
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  width: 0px;
  height: 5px;
  background-color: rgba(34, 34, 34, 0.94);
}

.scrollBar::-webkit-scrollbar-track {
  /*定义滚动条轨道 内阴影+圆角*/
  border-radius: 5px;
  background-color: rgba(34, 34, 34, 0.94);
}

.scrollBar::-webkit-scrollbar-thumb {
  /*定义滑块 内阴影+圆角*/
  border-radius: 5px;
  background-color: rgba(153, 153, 153, 0.6);
}

font {
  color: #1394ff;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tt_effect {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInDetail {
  0% {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes ripple {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  5% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes fadeInBg {
  from {
    color: #ffffff;
  }
  to {
    color: #1394ff;
  }
}
@keyframes loadingS {
  40% {
    transform: translateX(0);
    opacity: 0.8;
  }
  100% {
    transform: translateX(300px);
    opacity: 0;
  }
}
.loading-line-1 {
  animation: loadingS 4s infinite;
  animation-delay: 0.8s;
}

.loading-line-2 {
  animation: loadingS 4s infinite;
  animation-delay: 0.7s;
}

.loading-line-3 {
  animation: loadingS 4s infinite;
  animation-delay: 0.6s;
}

.loading-line-4 {
  animation: loadingS 4s infinite;
  animation-delay: 0.5s;
}

.loading-line-5 {
  animation: loadingS 4s infinite;
  animation-delay: 0.4s;
}

.loading-line-6 {
  animation: loadingS 4s infinite;
  animation-delay: 0.3s;
}

.loading-line-7 {
  animation: loadingS 4s infinite;
  animation-delay: 0.2s;
}

.loading-line-8 {
  animation: loadingS 4s infinite;
  animation-delay: 0.1s;
}

.loading-line {
  display: inline-block;
  background: #666;
  height: 10px;
  width: 10px;
  opacity: 0;
  border-radius: 50%;
  transform: translateX(-300px);
  background-color: #1394ff;
}

.loading {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%);
  opacity: 0;
}

